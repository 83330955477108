import { Injectable } from '@angular/core';

declare var $: any;

@Injectable()
export class AlertService {

    /**
     * @param text:string
     * This will set an alert error with the given text
     * Will not fade out
     */
    public setAlertError(text) {
        document.getElementById('alert-error').textContent = text;
        $('#alert-error').css('display', 'inherit');
        $('#alert-error').append(this.getButtonString('alert-error'));
    }

    /**
     * @param text:string
     * This will set an alert success with the given text
     * Will fade out after 5 seconds
     */
    public setAlertSuccess(text) {
        document.getElementById('alert-success').textContent = text;
        $('#alert-success').css('display', 'inherit');
        $('#alert-success').append(this.getButtonString('alert-success'));
        this.setFadeOut('alert-success');
    }

    /**
     * @param text:string
     * This will set an alert warning with the given text
     * Will fade out after 5 seconds
     */
    public setAlertWarning(text) {
        document.getElementById('alert-warning').textContent = text;
        $('#alert-warning').css('display', 'inherit');
        $('#alert-warning').append(this.getButtonString('alert-warning'));
        this.setFadeOut('alert-warning');
    }

    /**
     * @param text:string
     * This will set an alert info with the given text
     * Will fade out after 5 seconds
     */
    public setAlertInfo(text) {
        document.getElementById('alert-info').textContent = text;
        $('#alert-info').css('display', 'inherit');
        $('#alert-info').append(this.getButtonString('alert-info'));
        this.setFadeOut('alert-info');
    }

    /**
     * @param text:string
     * Returns the close button for the object
     */
    public getButtonString(text) {
        return '<button type=button class=close onclick=$("#' + text + '").hide() aria-label=Close><span aria-hidden=true>&times;</span></button>';
    }

    /**
     * @param text:string
     * Set the fadeOut of the given elementId
     */
    public setFadeOut(text) {
        $('#' + text).delay(5000).fadeOut('slow');
    }
}
