import { Component, OnInit } from '@angular/core';
import { Product } from '../../objects/product.object';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { CmsService } from '../../services/cms.service';
import { AlertService } from '../../services/alert.service';
import { SpinnerService } from '../../services/spinner.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

  public category: string = 'product';
  public id: number;
  public product: Product;
  private sub: any;

  constructor(private route: ActivatedRoute, private dataService: DataService, private cmsService: CmsService, private alertService: AlertService,
    private spinnerService: SpinnerService, private router: Router, private _location: Location) {
    this.product = new Product();
    this.spinnerService.start();
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getContent();
    });
  }

  public getContent() {
    this.dataService.getProductDetail(this.id).then((result) => {
      this.product = new Product().serialize(JSON.parse(result));
      this.spinnerService.stop();
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
        this.alertService.setAlertError('Niet geauthoriseerd, gelieve opnieuw in te loggen...');
      }
      console.log('Something went wrong...', err);
      this.spinnerService.stop();
    });
  }

  public radioChange(event): void {
    console.log('Radio changed!');
    console.log(event);
    this.spinnerService.start();
    this.cmsService.changeMainImageOfProduct(this.id, event.target.value).then((res) => {
      console.log('Main image successful updated!');
      this.getContent();
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
        this.alertService.setAlertError('Niet geauthoriseerd, gelieve opnieuw in te loggen...');
      }
      console.log('Something went wrong...', err);
      this.spinnerService.stop();
    });
  }

  public removeImage(index: number): void {
    if (this.product.img_src[index] === this.product.main_img_src) {
      this.alertService.setAlertError('Deze foto kan niet verwijderd worden omdat deze de hoofdfoto is. Gelieve eerst een andere foto te selecteren alvorens deze foto te wissen. Indien er geen andere foto\'s meer zijn, gelieve dan nieuwe foto\'s te uploaden of het product volledig te verwijderen.');
    } else {
      if (confirm('Bent u zeker dat u deze foto wil verwijderen van het product?')) {
        this.spinnerService.start();
        this.cmsService.removeImage(this.product.img_src[index]).then((res) => {
          console.log('Image succesful deleted!', res);
          this.getContent();
        }).catch((err) => {
          if (err === 'Unauthorized') {
            this.router.navigate(['home']);
          }
          console.log('Something went wrong...', err);
          this.spinnerService.stop();
        });
      }
    }
  }

  public submit(): void {
    this.spinnerService.start();
    this.cmsService.updateProduct(this.product).then((result) => {
      this.getContent();
      this.alertService.setAlertSuccess('Aanpassing succesvol!');
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
        this.alertService.setAlertError('Niet geauthoriseerd, gelieve opnieuw in te loggen...');
      }
      console.log('Something went wrong...', err);
      this.spinnerService.stop();
    });
  }

  public removeProduct(): void {
    if (confirm("Bent u zeker dat u dit product volledig wil verwijderen?")) {
      if (confirm("Extra veiligheid: product verwijderen?")) {
        this.spinnerService.start();
        this.cmsService.removeProduct(this.id).then((res) => {
          console.log('Product verwijderd!');
          this.spinnerService.stop();
          this.alertService.setAlertSuccess('Product verwijderd!');
          this._location.back();
        }).catch((err) => {
          if (err === 'Unauthorized') {
            this.router.navigate(['home']);
            this.alertService.setAlertError('Niet geauthoriseerd, gelieve opnieuw in te loggen...');
          }
          console.log('Something went wrong...', err);
          this.spinnerService.stop();
        });
      }
    }
  }

}
