export class ActionEditor {
    public id: number;
    public description: string;
    public currentAction: string;

    constructor(id?: number, description?: string, currentAction?: string) {
        if (id) {
            this.id = id;
        } else {
            this.id = 0;
        }
        if (description) {
            this.description = description;
        } else {
            this.description = 'ACTION';
        }
        if (currentAction) {
            this.currentAction = currentAction;
        } else {
            this.currentAction = "";
        }
    }

    public clone(): ActionEditor {
        return new ActionEditor(this.id, this.description, this.currentAction);
    }

    public equals(other: ActionEditor): boolean {
        return this.description === other.description && this.currentAction === other.currentAction;
    }

    public serialize(result: any): ActionEditor {
        if (result.hasOwnProperty('id')) {
            this.id = result.id;
        }
        if (result.hasOwnProperty('description')) {
            this.description = result.description;
        } 
        if (result.hasOwnProperty('currentAction')) {
            this.currentAction = result.currentAction;
        }
        return this;
    }
}