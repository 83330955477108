import { Component, OnInit } from '@angular/core';
import { Category } from '../objects/category.object';
import { SubCategory } from '../objects/subcategory.object';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  category: Category;
  subcategories: SubCategory[];
  img_test_photo: any;
  private sub: any;

  constructor(private route: ActivatedRoute, private dataService: DataService, private spinnerService: SpinnerService) {
    this.category = new Category();
    this.spinnerService.start();
  }

  public ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.category = new Category();
      this.category.name = params['cat'];
      this.subcategories = [];
      this.dataService.getCategoryIntro(this.category.name).then((result: any) => {
        const obj: any = JSON.parse(result);
        this.category.description = obj.description;
        this.category.hasSub = obj.hasSub;
        if (this.category.hasSub) {
          this.dataService.getSubCategories(this.category.name).then((result: any) => {
            for (const a of result) {
              this.subcategories.push(new SubCategory().serialize(a));
            }
            this.spinnerService.stop();
          });
        } else {
          this.dataService.getCategoryWithImages(this.category.name).then((result: any) => {
            if (result.name !== "None") {
              this.category.serialize(result);
            }
            this.spinnerService.stop();
          });
        }
      });
    });
  }

}
