import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '../../../node_modules/@angular/router';
import { Product } from '../objects/product.object';
import { DataService } from '../services/data.service';
import { AlertService } from '../services/alert.service';
import { SpinnerService } from '../services/spinner.service';
import { CmsService } from '../services/cms.service';

@Component({
  selector: 'app-product-editor',
  templateUrl: './product-editor.component.html',
  styleUrls: ['./product-editor.component.scss']
})
export class ProductEditorComponent implements OnInit {

  private sub: any;
  public subCategoryId: number;
  public subCategoryName: string;
  public products: Product[];

  constructor(private route: ActivatedRoute, private dataService: DataService, private router: Router, private alertService: AlertService,
    private spinnerService: SpinnerService, private cmsService: CmsService) {
    this.subCategoryId = 0;
    this.subCategoryName = '';
    this.products = [];
  }

  ngOnInit() {
    this.spinnerService.start();
    this.sub = this.route.params.subscribe(params => {
      this.subCategoryId = params['id'];
      this.subCategoryName = params['name'];
      this.dataService.getSubCategoryProductOverview(this.subCategoryName).then((result) => {
        let object = JSON.parse(JSON.parse(result));
        for (const product_obj of object) {
          this.products.push(new Product().serialize(product_obj));
        }
        this.spinnerService.stop();
      }).catch((err) => {
        if (err === 'Unauthorized') {
          this.router.navigate(['home']);
          this.alertService.setAlertError('Niet geauthoriseerd, gelieve opnieuw in te loggen...');
        }
        console.log('Something went wrong...', err);
        this.spinnerService.stop();
      });
    });
  }

  public addProduct(id: number): void {
    this.spinnerService.start();
    this.cmsService.addProduct(this.subCategoryId).then((result) => {
      console.log(result);
      if (result > 0) {
        this.alertService.setAlertSuccess('Niew product succesvol aangemaakt!');
        this.router.navigate(['/editor', result]);
      } else {
        this.spinnerService.stop();
        this.alertService.setAlertWarning('Nieuw product niet aangemaakt... Controleer de console en probeer opnieuw.');
      }
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
        this.alertService.setAlertError('Niet geauthoriseerd, gelieve opnieuw in te loggen...');
      }
      console.log('Something went wrong...', err);
      this.spinnerService.stop();
    });
  }

}
