import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PageContent } from './../objects/pagecontent.object';

declare let $: any;

@Injectable()
export class DataService {
    constructor(private router: Router) {
    }

    public getHomePageContent(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/homepage/getAllContent',
                accept: 'application/json',
                success(result) {
                    if (result.data) {
                        let res = result.data.split(']');
                        if (result.type === 'success') {
                            let a = JSON.parse(result.data);
                            resolve(a);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public getActionsPageContent(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/actionpage/getAllActions',
                accept: 'application/json',
                success(result) {
                    if (result.type === 'success') {
                        let a = JSON.parse(result.data);
                        resolve(a);
                    } else {
                        reject(result.data);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public getAboutPageContent(): Promise<PageContent[]> {
        return new Promise<PageContent[]>((resolve, reject) => {
            $.ajax({
                method: "GET",
                url: '/ws/aboutpage/getAllContent',
                accept: 'application/json',
                success(result) {
                    if (result.type === 'success') {
                        let res: PageContent[] = [];
                        let a = JSON.parse(result.data);
                        $.each(a, (index, value) => {
                            let pageContent: PageContent;
                            if (value.img_paths) {
                                pageContent = new PageContent(value.name, value.content, value.img_paths[0]);
                            } else {
                                pageContent = new PageContent(value.name, value.content);
                            }
                            res.push(pageContent);
                        });
                        resolve(res);
                    } else {
                        let pageContent: PageContent = new PageContent("Error", result.data);
                        reject([pageContent]);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        let pageContent: PageContent = new PageContent("Error", "Unauthorized");
                        reject([pageContent]);
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        let pageContent: PageContent = new PageContent("Error", 'Something went wrong... Please check your console and contact the system administrator.');
                        reject([pageContent]);
                    }
                }
            });
        });
    }

    public getContactPageContent(): Promise<PageContent> {
        return new Promise<PageContent>((resolve, reject) => {
            $.ajax({
                method: "GET",
                url: '/ws/contactpage/getContent',
                accept: 'application/json',
                success(result) {
                    if (result.type === 'success') {
                        let a = JSON.parse(result.data);
                        let pageContent = new PageContent(a.name, a.content);
                        resolve(pageContent);
                    } else {
                        let pageContent: PageContent = new PageContent('Error', result.data);
                        reject(pageContent);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        let pageContent: PageContent = new PageContent("Error", "Unauthorized");
                        reject([pageContent]);
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        let pageContent: PageContent = new PageContent("Error", 'Something went wrong... Please check your console and contact the system administrator.');
                        reject([pageContent]);
                    }
                }
            });
        });
    }

    public getSubNavigationItems(): Promise<string[]> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/navigation/getSubNavigationItems',
                accept: 'application/json',
                success(result) {
                    if (result.type === 'success') {
                        let a = JSON.parse(result.data);
                        resolve(a);
                    } else {
                        reject(result.data);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            })
        })
    }

    public getCategoryIntro(category: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/category/description',
                data: { cat: category },
                dataType: 'json',
                accept: 'application/json',
                success(result) {
                    if (result.type === 'success') {
                        resolve(result.data);
                    } else {
                        reject(result.data);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            })
        });
    }

    public getSubCategories(category: string): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            $.ajax({
                method: "GET",
                url: "/ws/category/subcategories",
                data: { cat: category },
                dataType: "json",
                accept: 'application/json',
                success(result) {
                    if (result.type === 'success') {
                        resolve(JSON.parse(result.data));
                    } else {
                        reject(result.data);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            })
        })
    }

    public getCategoryWithImages(category: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: 'ws/category/withimages',
                data: { cat: category },
                dataType: 'JSON',
                accept: 'application/json',
                success(result) {
                    if (result.type === 'success') {
                        resolve(JSON.parse(result.data));
                    } else {
                        reject(result.data);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public getSubCategoryIntro(subCategory: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            $.ajax({
                method: "GET",
                url: "ws/subcategory/description",
                data: { subcat: subCategory },
                dataType: "json",
                accept: "application/json",
                success(result) {
                    if (result.type === 'success') {
                        resolve(result.data);
                    } else {
                        reject(result.data);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public getSubCategoryProductOverview(subCategory: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            $.ajax({
                method: "GET",
                url: "ws/subcategory/overview",
                data: { subcat: subCategory },
                dataType: "json",
                accept: "application/json",
                success(result) {
                    if (result.type === 'success') {
                        resolve(result.data);
                    } else {
                        reject(result.data);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            })
        });
    }

    public getProductDetail(id: number): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            $.ajax({
                method: "GET",
                url: "ws/product/detail",
                data: { id: id },
                dataType: "json",
                accept: "application/json",
                success(result) {
                    if (result.type === 'success') {
                        resolve(result.data);
                    } else {
                        reject(result.data);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }
}