import { Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { CategoryComponent } from "./category/category.component";
import { SubcategoryComponent } from "./subcategory/subcategory.component";
import { ProductComponent } from "./product/product.component";
import { AboutComponent } from "./about/about.component";
import { ActionsComponent } from "./actions/actions.component";
import { ContactComponent } from "./contact/contact.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { HomeEditorComponent } from "./home-editor/home-editor.component";
import { ActionsEditorComponent } from "./actions-editor/actions-editor.component";
import { CategoryEditorComponent } from "./category-editor/category-editor.component";
import { SubcategoryEditorComponent } from "./subcategory-editor/subcategory-editor.component";
import { ProductEditorComponent } from "./product-editor/product-editor.component";
import { EditorComponent } from "./product-editor/editor/editor.component";

export const ROUTES: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'category/:cat', component: CategoryComponent },
    { path: 'subcategory/:subcat', component: SubcategoryComponent },
    { path: 'product/:id', component: ProductComponent },
    { path: 'about', component: AboutComponent },
    { path: 'actions', component: ActionsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'login', component: LoginComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'home-editor', component: HomeEditorComponent },
    { path: 'actions-editor', component: ActionsEditorComponent },
    { path: 'category-editor', component: CategoryEditorComponent },
    { path: 'subcategory-editor', component: SubcategoryEditorComponent },
    { path: 'product-editor/:id/:name', component: ProductEditorComponent },
    { path: 'editor/:id', component: EditorComponent }
]