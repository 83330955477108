import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from '../services/spinner.service';
import { UserService } from '../services/user.service';
import { AlertService } from '../services/alert.service';

declare let $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('buttonSignIn') public buttonSignIn: ElementRef;
  @ViewChild('loginSpinner') public loginSpinner: ElementRef;

  public submitted = false;

  public inputEmail: string;
  public inputPassword: string;
  public inputRemember: boolean;

  constructor(private router: Router, private spinnerService: SpinnerService, private userService: UserService,
    private alertService: AlertService) {
    this.spinnerService.start();
  }

  public ngOnInit() {
    if (!this.areCookiesEnabled()) {
      alert('Cookies need to be enabled for using this website. Please enable your cookies and refresh this page before continuing...');
      $(this.buttonSignIn.nativeElement).prop('disabled', true);
  }
  $(this.loginSpinner.nativeElement).css('display', 'none');
  this.spinnerService.stop();
  }

  /**
     * For check of cookies are enabled
     */
    public createCookie(name, value, days) {
      let expires;
      if (days) {
          let date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toISOString;
      } else { expires = ''; }
      document.cookie = name + '=' + value + expires + '; path=/';
  }

  /**
   * For check of cookies are enabled
   */
  public readCookie(name) {
      let nameEQ = name + '=';
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
          if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length, c.length); }
      }
      return null;
  }

  /**
   * For check of cookies are enabled
   */
  public eraseCookie(name) {
      this.createCookie(name, '', -1);
  }

  /**
   * For check of cookies are enabled
   */
  public areCookiesEnabled() {
      let r = false;
      this.createCookie('testing', 'Hello', 1);
      if (this.readCookie('testing') != null) {
          r = true;
          this.eraseCookie('testing');
      }
      return r;
  }

  /**
     * Encrypt pass in MD5
     * Check the credentials
     */
    public onSubmit() {
      this.submitted = true;
      $(this.loginSpinner.nativeElement).css('display', 'inline-block');
      $(this.buttonSignIn.nativeElement).html('Aanmelden...');
      if (this.inputEmail && this.inputPassword) {
          console.log(this.inputPassword);
          console.log(this.inputEmail);
          // CryptoJS.MD5(this.inputPassword)
          this.userService.login(this.inputEmail, this.inputPassword, this.inputRemember).then((obj) => {
              console.log(obj);
              if (obj.type === 'success') {
                  this.router.navigate(['dashboard']);
                  this.alertService.setAlertSuccess('You are successfully logged in!');
              } else if (obj.type === 'unauthorized') {
                  this.removeLoginSpinner();
                  this.alertService.setAlertWarning(obj.data.message + '... Please try again.');
              } else if (obj.type === 'blacklist') {
                  this.removeLoginSpinner();
                  let level = obj.data.message;
                  this.alertService.setAlertError('You are blacklisted from this address... Please wait ' + Math.pow(5, level) + ' minutes before another attemp. Please contact the sysadmin if this error was by purpose...');
              } else {
                  this.removeLoginSpinner();
                  this.alertService.setAlertError('Something went wrong during the login procedure. Please check the information in your console and contact the system administrator.');
              }
          }).catch((err) => {
              this.removeLoginSpinner();
              this.alertService.setAlertError('Something went wrong during the login procedure. Please check the information in your console and contact the system administrator.');
          });
      }
  }

  /**
     * @param event:any
     * Called when clicked on Sign in buttonSignIn
     * Calls onSubmit()
     */
    public clickLogin(event) {
      this.onSubmit();
  }

  /**
   * Remove the spinner at the button
   * Set the initial text back
   */
  public removeLoginSpinner() {
      $(this.loginSpinner.nativeElement).css('display', 'none ');
      $(this.buttonSignIn.nativeElement).html('Meld aan ');
  }

}
