import { Category } from './category.object';

export class SubCategory {
    name: string;
    description: string;
    category: Category;
    img_src: string;
    subCategoryId: number;

    constructor(name?: string, description?: string, img_src?: string, category?: Category) {
        if (name) {
            this.name = name;
        } else {
            this.name = 'undefined';
        }
        if (description) {
            this.description = description;
        }
        if (category) {
            this.category = category.clone();
        } else {
            this.category = new Category();
        }
        if (img_src) {
            this.img_src = img_src;
        } else {
            this.img_src = '';
        }
    }

    public clone(): SubCategory {
        return new SubCategory(this.name, this.description, this.img_src, this.category.clone());
    }

    public equals(other: SubCategory): boolean {
        return this.name === other.name && this.description === other.description && this.category.equals(other.category) && this.img_src === other.img_src;
    }

    public serialize(object: any): SubCategory {
        if (object.hasOwnProperty('name')) {
            this.name = object.name;
        }
        if (object.hasOwnProperty('imagePath')) {
            this.img_src = object.imagePath;
        }
        if (object.hasOwnProperty('description')) {
            this.description = object.description;
        }
        if (object.hasOwnProperty('subCategoryId')) {
            this.subCategoryId = object.subCategoryId;
        }
        if (object.hasOwnProperty('img_src')) {
            this.img_src = object.img_src;
        }
        return this;
    }
}