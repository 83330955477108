export class Category {
    name: string;
    description: string;
    hasSub: boolean;
    img_paths: string[];
    img_test: any[];
    id: number;

    constructor(name?: string, description?: string, hasSub?: boolean, img_paths?: string[], id?: number) {
        if (name) {
            this.name = name;
        } else {
            this.name = 'undefined';
        }
        if (description) {
            this.description = description;
        } else {
            this.description = 'not given...';
        }
        if (hasSub) {
            this.hasSub = hasSub;
        } else {
            this.hasSub = false;
        }
        if (img_paths) {
            this.img_paths = img_paths;
        } else {
            this.img_paths = [];
        }
        if (id) {
            this.id = id;
        } else {
            this.id = 0;
        }
    }

    public clone(): Category {
        return new Category(this.name, this.description, this.hasSub, this.img_paths, this.id);
    }

    public equals(other: Category): boolean {
        return this.name === other.name && this.description === other.description;
    }

    public serialize(result: any): Category {
        if (result.hasOwnProperty('name')) {
            this.name = result.name;
        }
        if (result.hasOwnProperty('description')) {
            this.description = result.description;
        }
        if (result.hasOwnProperty('hasSub')) {
            this.hasSub = result.hasSub;
        }
        if (result.hasOwnProperty('img_paths')) {
            this.img_paths = result.img_paths;
        }
        if (result.hasOwnProperty('image_test')) {
            this.img_test = result.image_test;
        } else {
            this.img_test = [];
        }
        if (result.hasOwnProperty('categoryId')) {
            this.id = result.categoryId;
        }
        if (result.hasOwnProperty('id')) {
            this.id = result.id;
        }
        return this;
    }
}