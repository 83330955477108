import { Component, OnInit } from '@angular/core';
import { DataService } from './services/data.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';

  public subNavigationItems: string[];

  constructor(private dataService: DataService) { }

  public ngOnInit(): void {
    //$("#navtop").show();
    //$("#navigation").removeClass('navbar-hided');
    //$("#first-to-hide-nav").hide();
    $(window).scroll(() => {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        document.getElementById("navtop").style.padding = "0px 0px";
        document.getElementById("navigation").style.minHeight = "0px";
        document.getElementById("navbar-logo").style.height = "40px";
        document.getElementById("my-nav-items").style.fontSize = "medium";
        document.getElementById("my-nav-items").style.marginTop = "15px";
      } else {
        document.getElementById("navtop").style.padding = "0px 0px 25px 0px";
        document.getElementById("navigation").style.minHeight = "80px";
        document.getElementById("navbar-logo").style.height = "75px";
        document.getElementById("my-nav-items").style.fontSize = "large";
        document.getElementById("my-nav-items").style.marginTop = "30px";
      }
    });
    /*this.dataService.getSubNavigationItems().then((result) => {
      this.subNavigationItems = result;
      window.setTimeout(this.test, 500);
    });
    $('.navbar-collapse a').click(function () {
      $(".navbar-collapse").collapse('hide');
    });*/
  }

  public hideNavBar(): void {
    $('.navbar-collapse').collapse('hide');
  }

  public navigateTo(): void {
    window.open("https://meubelbewaring-waasland.be/#/", "_blank");
  }

  /*private test(): void {
    $('#mySideNav.navbar-collapse a').click(() => {
      $("#mySideNav.navbar-collapse").collapse('hide');
    });
  }*/

}
