import { Component, OnInit } from '@angular/core';
import { PageContent } from '../objects/pagecontent.object';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public items: PageContent[];

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.getAboutPageContent().then((result: PageContent[]) => {
      this.items = result;
    }).catch((err) => {
      console.log(err);
    });
  }

}
