export class Actions {
    description: string;
    currentActions: string[];

    constructor(description?: string, currentActions?: string[]) {
        if (description) {
            this.description = description;
        } else {
            this.description = 'None';
        }
        if (currentActions) {
            this.currentActions = currentActions;
        } else {
            this.currentActions = [];
        }
    }

    public clone(): Actions {
        return new Actions(this.description, this.currentActions);
    }

    public equals(other: Actions): boolean {
        return this.description === other.description && this.currentActions === other.currentActions;
    }

    public serialize(result: any): Actions {
        if (result.hasOwnProperty('description')) {
            this.description = result.description;
        } else {
            this.description = "";
        }
        if (result.hasOwnProperty('currentActions')) {
            this.currentActions = result.currentActions;
        } else {
            this.currentActions = [];
        }
        return this;
    }
}