export class PageContent {
    name: string;
    content: string;
    img: string;
    constructor(name: string, content: string, img?: string) {
        this.name = name;
        this.content = content;
        if (img) {
            this.img = img;
        } else {
            this.img = "";
        }
    }
}