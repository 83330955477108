import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ActionEditor } from "../objects/action-editor.object";
import { Category } from "../objects/category.object";
import { SubCategory } from "../objects/subcategory.object";
import { Product } from "../objects/product.object";

declare let $: any;

@Injectable()
export class CmsService {

    constructor(private router: Router) {
    }

    public getDashboardContent(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/dashboard/test',
                accept: 'application/json',
                success(result) {
                    console.log('sucesss:', result);
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public updateHomePage(shortIntro: string, openingHours: string, contact: string, banner: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'POST',
                data: JSON.stringify({ shortIntro: shortIntro, openingHours: openingHours, contact: contact, banner: banner }),
                contentType: "application/json; charset=utf-8",
                url: '/ws/homepage/update',
                accept: 'application/json',
                success(result) {
                    console.log('Update Home Page success!');
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            })
        });
    }

    public getActionsEditorPageContent(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/actionpage/getActionsEditor',
                accept: 'application/json',
                success(result) {
                    if (result.type === 'success') {
                        let a = JSON.parse(result.data);
                        resolve(a);
                    } else {
                        reject(result.data);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public updateActions(actions: ActionEditor[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'POST',
                data: JSON.stringify(actions),
                contentType: "application/json; charset=utf-8",
                url: '/ws/actionpage/update',
                accept: 'application/json',
                success(result) {
                    console.log('Update Actions success!');
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            })
        });
    }

    public getCategoriesEditorContent(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/category/full',
                accept: 'application/json',
                success(result) {
                    if (result.type === 'success') {
                        let a = JSON.parse(result.data);
                        resolve(a);
                    } else {
                        reject(result.data);
                    }
                },
                error(xhr, status, error) {
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public updateCategory(category: Category): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'POST',
                data: JSON.stringify(category),
                contentType: "application/json; charset=utf-8",
                url: '/ws/category/update_category',
                accept: 'application/json',
                success(result) {
                    console.log('Update Category success!');
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            })
        });
    }

    public removeImage(imagePath: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                data: { path: imagePath },
                dataType: "JSON",
                url: '/ws/dashboard/delete_image',
                accept: 'application/json',
                success(result) {
                    console.log('Delete image success!');
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public getAllSubCategories(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/subcategory/getAll',
                accept: 'application/json',
                success(result) {
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public updateSubCategory(s: SubCategory): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'POST',
                url: '/ws/subcategory/update',
                data: JSON.stringify(s),
                contentType: "application/json; charset=utf-8",
                accept: 'application/json',
                success(result) {
                    console.log('Update Subcategory success!');
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public changeMainImageOfProduct(productId: number, path: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/product/changeMainImage',
                data: { productId: productId, imagePath: path },
                dataType: "JSON",
                accept: 'application/json',
                success(result) {
                    console.log('Change main image success!');
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            })
        });
    }

    public updateProduct(p: Product): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'POST',
                url: '/ws/product/update',
                data: JSON.stringify(p),
                contentType: "application/json; charset=utf-8",
                accept: 'application/json',
                success(result) {
                    console.log('Update Product success!');
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            })
        });
    }

    public removeProduct(productId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/product/remove',
                data: { id: productId },
                dataType: "JSON",
                accept: 'application/json',
                success(result) {
                    console.log('Product deleted!');
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }

    public addProduct(subCategoryId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/ws/product/add',
                data: { subcatId: subCategoryId },
                dataType: 'JSON',
                accept: 'application/json',
                success(result) {
                    console.log('Product created!');
                    if (result.data) {
                        if (result.type === 'success') {
                            resolve(result.data);
                        } else {
                            reject(result.data);
                        }
                    } else {
                        reject("error");
                    }
                },
                error(xhr, status, error) {
                    console.log('error: ', xhr, status, error);
                    if (error === 'Unauthorized') {
                        reject('Unauthorized');
                    } else {
                        console.log(error);
                        console.log(status);
                        console.log(xhr);
                        reject('Something went wrong... Please check your console and contact the system administrator.');
                    }
                }
            });
        });
    }
}