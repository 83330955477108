import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { PageContent } from '../objects/pagecontent.object';

declare let google: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public content: string = '';

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.getContactPageContent().then((result: PageContent) => {
      this.content = result.content;
    }).catch((err: PageContent) => {
      this.content = 'Error retrieving data from server... Please refresh.';
    });

    let mapOptions = {
      zoom: 15,
      center: new google.maps.LatLng(51.2333023, 4.1022504)
    }
    let map = new google.maps.Map(document.getElementById('map-canvas'),
      mapOptions);

    let image = 'assets/img/flaviconMaps.png';
    let myLatLng = new google.maps.LatLng(51.2333023, 4.1022504);
    let contentString = '<div id="content">' +
      '<div id="siteNotice">' +
      '</div>' +
      '<h2 id="firstHeading" class="firstHeading">Meubelen De Smedt</h2>' +
      '<img src="assets/img/gebouw.jpg" alt="gebouw" height="85" style="float:right;">' +
      '<div id="bodyContent">' +
      '<p>Kluizendijkstraat 9<br>9170 Sint-Gillis-Waas<br><br>Tel.: 03/770.57.46<br>Fax: 03/707.01.91<br>Email: <a href="mailto:meubelen.desmedt@skynet.be">meubelen.desmedt@skynet.be</a></p>' +
      '</div>' +
      '</div>';

    let infowindow = new google.maps.InfoWindow({
      content: contentString,
      maxWidth: 400
    });

    let marker = new google.maps.Marker({
      position: myLatLng,
      animation: google.maps.Animation.DROP,
      icon: image,
      title: "Meubelen De Smedt"
    });

    google.maps.event.addListener(marker, 'click', function () {
      infowindow.open(map, marker);
    });

    marker.setMap(map);
  }

}
