import { Category } from './category.object';
import { SubCategory } from './subcategory.object';

export class Product {
    id: number;
    name: string;
    description: string;
    category: Category;
    subCategory: SubCategory = null;
    img_src: string[];
    productN: string;
    main_img_src: string;

    constructor(id?: number, name?: string, description?: string, category?: Category, subCategory?: SubCategory, img_src?: string[], productN?: string) {

        if (id) {
            this.id = id;
        }
        if (name) {
            this.name = name;
        }
        if (description) {
            this.description = description;
        }
        if (category) {
            this.category = category.clone();
        }
        if (subCategory) {
            this.subCategory = subCategory.clone();
        } else {
            this.subCategory = new SubCategory();
        }
        if (img_src) {
            this.img_src = img_src;
        } else {
            this.img_src = [];
        }
        if (productN) {
            this.productN = productN;
        } else {
            this.productN = '';
        }
    }

    public equals(other: Product): boolean {
        return this.name === other.name && this.description === other.description && this.category.equals(other.category) && this.subCategory.equals(other.subCategory);
    }

    public clone(): Product {
        const img_res_back = [];
        for (let i = 0; i < this.img_src.length; i++) {
            img_res_back.push(this.img_src[i]);
        }
        return new Product(this.id, this.name, this.description, this.category.clone(), this.subCategory.clone(), img_res_back);
    }

    public serialize(result: any) {
        if (result.hasOwnProperty('id')) {
            this.id = result.id;
        }
        if (result.hasOwnProperty('description')) {
            this.description = result.description;
        }
        if (result.hasOwnProperty('main_img_path')) {
            this.main_img_src = result.main_img_path;
        }
        if (result.hasOwnProperty('main_img_src')) {
            this.main_img_src = result.main_img_src;
        }
        if (result.hasOwnProperty('name')) {
            this.name = result.name;
        }
        if (result.hasOwnProperty('img_paths')) {
            this.img_src = result.img_paths;
        }
        if (result.hasOwnProperty('img_src')) {
            this.img_src = result.img_src;
        }
        if (result.hasOwnProperty('subCatName')) {
            this.subCategory = new SubCategory(result.subCatName);
        }
        if (result.hasOwnProperty('productN')) {
            this.productN = result.productN;
        }
        return this;
    }
}