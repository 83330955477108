import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule, PreloadAllModules} from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { DataService } from './services/data.service';
import { SpinnerService } from './services/spinner.service';
import { AboutComponent } from './about/about.component';
import { ActionsComponent } from './actions/actions.component';
import { CategoryComponent } from './category/category.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { ROUTES } from './app.routes';
import { LoginComponent } from './login/login.component';
import { UserService } from './services/user.service';
import { AlertService } from './services/alert.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CmsService } from './services/cms.service';
import { HomeEditorComponent } from './home-editor/home-editor.component';
import { ActionsEditorComponent } from './actions-editor/actions-editor.component';
import { CategoryEditorComponent } from './category-editor/category-editor.component';
import { SubcategoryEditorComponent } from './subcategory-editor/subcategory-editor.component';
import { ProductEditorComponent } from './product-editor/product-editor.component';
import { UploadModule } from './upload/upload.module';
import { EditorComponent } from './product-editor/editor/editor.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ActionsComponent,
    CategoryComponent,
    ContactComponent,
    FooterComponent,
    HomeComponent,
    ProductComponent,
    SpinnerComponent,
    SubcategoryComponent,
    LoginComponent,
    DashboardComponent,
    HomeEditorComponent,
    ActionsEditorComponent,
    CategoryEditorComponent,
    SubcategoryEditorComponent,
    ProductEditorComponent,
    EditorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      //preloadingStrategy: PreloadAllModules
    }),
    UploadModule
  ],
  providers: [ DataService, SpinnerService, UserService, AlertService, CmsService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
