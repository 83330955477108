import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { CmsService } from '../services/cms.service';
import { SpinnerService } from '../services/spinner.service';
import { AlertService } from '../services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-editor',
  templateUrl: './home-editor.component.html',
  styleUrls: ['./home-editor.component.scss']
})
export class HomeEditorComponent implements OnInit {

  public shortIntro: string;
  public openingHours: string;
  public contact: string;
  public banner: string;
  public result: boolean = false;
  public img_paths: string[];

  public category: string = 'home';

  constructor(private dataService: DataService, private cmsService: CmsService, private spinnerService: SpinnerService,
  private alertService:AlertService, private router: Router) {
    this.spinnerService.start();
  }

  ngOnInit() {
    this.getAllContent();
  }

  public check() {
    this.result = true;
  }

  public submit() {
    this.cmsService.updateHomePage(this.shortIntro, this.openingHours, this.contact, this.banner).then((result) => {
      this.alertService.setAlertSuccess("Gegevens succesvol aangepast!");
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
      }
      this.alertService.setAlertError("Iets is fout gegaan... Controleer de console en probeer opnieuw");
      console.log(err);
    })
  }

  public removeImage(index: number) {
    if (confirm('Bent u zeker dat u deze afbeelding wenst te verwijderen?')) {
      this.spinnerService.start();
      console.log(index);
      console.log(this.img_paths);
      const removedPath: string[] = this.img_paths.splice(index, 1);
      console.log(removedPath);
      const removedImage: string = removedPath[0];
      console.log(removedImage);
      this.cmsService.removeImage(removedImage).then((res) => {
        console.log('Image succesful deleted!', res);
        this.getAllContent();
      }).catch((err) => {
        if (err === 'Unauthorized') {
          this.router.navigate(['home']);
        }
        console.log('Something went wrong...', err);
        this.spinnerService.stop();
      });
    }
  }

  private getAllContent(): void {
    this.dataService.getHomePageContent().then((obj: any) => {
      this.shortIntro = obj[0]['content'];
      this.openingHours = obj[1]['content'];
      this.contact = obj[2]['content'];
      this.banner = obj[3]['content'];
      this.img_paths = obj[4]['img_paths'];
      this.spinnerService.stop();
    }).catch((err) => {
      alert("Something went wrong!");
      this.spinnerService.stop();
    });
  }

}
