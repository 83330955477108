import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';
import { UploadService } from './upload.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {

  @Input() first: any;
  @Input('second') second: any;

  constructor(public dialog: MatDialog, public uploadService: UploadService) { }

  public openUploadDialog() {
    console.log(this.first, this.second);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      first: this.first,
      second: this.second
    };
    dialogConfig.width = '50%';
    dialogConfig.height = '50%';
    // let dialogRef = this.dialog.open(DialogComponent, { width: '50%', height: '50%' });
    let dialogRef = this.dialog.open(DialogComponent, dialogConfig);
  }
}