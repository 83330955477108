import { Component, OnInit } from '@angular/core';
import { Product } from '../objects/product.object';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { SpinnerService } from '../services/spinner.service';

declare let $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  private sub: any;
  public id: number;
  public product: Product;

  constructor(private route: ActivatedRoute, private dataService: DataService, private spinnerService: SpinnerService) {
    this.product = new Product();
    this.spinnerService.start();
  }

  public ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.dataService.getProductDetail(this.id).then((result) => {
        this.product = new Product().serialize(JSON.parse(result));
        window.setTimeout(this.initCarousel, 500);
        this.spinnerService.stop();
      });
    });
  }

  private initCarousel(): void {
    let primary = $("#carousel");
    let secondary = $("#thumbnails");

    primary.owlCarousel({
      loop: true,
      items: 1,
      dots: false,
      nav: false,
      thumbs: true,
      thumbImage: true,
      thumbContainerClass: 'owl-thumbs',
      thumbItemClass: 'owl-thumb-item'
    });
  }

}
