import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

declare var $: any;
declare var localStorage: any;

@Injectable()
export class UserService {

    private loggedIn = false;

    constructor(private router: Router) { }

     /**
     * @param email:stringify
     * @param password:string
     * @param remember:boolean
     * Sends loginCredentials to the webservice and redirect to lijst if success or display an alert when something is wrong
     */
    public login(email, password, remember) {
        return new Promise<any>((resolve, reject) => {
            $.ajax({
                method: 'POST',
                url: '/ws/doauth',
                data: JSON.stringify({ 'email': email, 'pass': password, 'remember': remember }),
                dataType: 'json',
                contentType: 'application/json; charset=utf-8',
                success(data) {
                    resolve(data);
                },
                error(xhr, status, error) {
                    console.log(error);
                    console.log(status);
                    console.log(xhr);
                    reject(error);
                }
            });
        });
    }
}