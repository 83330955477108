import { Component, OnInit } from '@angular/core';
import { Category } from '../objects/category.object';
import { AlertService } from '../services/alert.service';
import { CmsService } from '../services/cms.service';
import { Router } from '@angular/router';
import { SpinnerService } from '../services/spinner.service';

declare var $: any;

@Component({
  selector: 'app-category-editor',
  templateUrl: './category-editor.component.html',
  styleUrls: ['./category-editor.component.scss']
})
export class CategoryEditorComponent implements OnInit {

  public categories: Category[];
  private categories_back: Category[];
  public category = 'category';

  constructor(private alertService: AlertService, private cmsService: CmsService, private router: Router, private spinnerService: SpinnerService) {
    this.categories = [];
    this.categories_back = [];
    this.spinnerService.start();
  }

  ngOnInit() {
    this.getContent();
  }

  public submit(name: string, index: number): void {
    console.log('submit...', name);
    this.spinnerService.start();
    this.cmsService.updateCategory(this.categories[index]).then((res) => {
      console.log('Result after updating category...', res);
      this.categories = [];
      this.categories_back = [];
      this.getContent();
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
      }
      console.log('Something went wrong...', err);
      this.spinnerService.stop();
    });
  }

  public removeImage(categoryIndex: number, imageIndex: number): void {
    if (confirm('Bent u zeker dat u deze afbeelding wenst te verwijderen?')) {
      this.spinnerService.start();
      const removedPath: string[] = this.categories[categoryIndex].img_paths.splice(imageIndex, 1);
      const removedImage: string = removedPath[0];
      this.cmsService.removeImage(removedImage).then((res) => {
        console.log('Image succesful deleted!', res);
        this.categories = [];
        this.categories_back = [];
        this.getContent();
      }).catch((err) => {
        if (err === 'Unauthorized') {
          this.router.navigate(['home']);
        }
        console.log('Something went wrong...', err);
        this.spinnerService.stop();
      });
    }
  }

  private getContent(): void {
    this.cmsService.getCategoriesEditorContent().then((obj: any) => {
      console.log('got it: ', obj);
      for (let i = 0; i < obj.length; i++) {
        this.categories.push(new Category().serialize(obj[i]));
        this.categories_back.push(new Category().serialize(obj[i]));
      }
      this.spinnerService.stop();
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
      }
      console.log('Something went wrong...', err);
      this.spinnerService.stop();
    });
  }

}
