import { Component, OnInit } from '@angular/core';
import { Actions } from '../objects/actions.object';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  public action: Actions;

  constructor(private dataService: DataService) {
    this.action = new Actions();
  }

  ngOnInit() {
    this.dataService.getActionsPageContent().then((result: any) => {
      this.action = new Actions().serialize(result);
    }).catch((err) => {
      console.log(err);
    });
  }

}
