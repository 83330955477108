import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { CmsService } from '../services/cms.service';
import { Router } from '@angular/router';
import { SubCategory } from '../objects/subcategory.object';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public subCategories: SubCategory[];

  constructor(private alertService: AlertService, private cmsService: CmsService, private router: Router) {
    this.subCategories = [];
  }

  ngOnInit() {
    this.cmsService.getDashboardContent().then((result) => {
      console.log('THEN: ', result);
    }).catch((err) => {
      console.log('ERR:', err);
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
      }
    });
    this.cmsService.getAllSubCategories().then((result) => {
      for (let i = 0; i < result.length; i++) {
        this.subCategories.push(new SubCategory().serialize(result[i]));
      }
      console.log(this.subCategories);
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
        this.alertService.setAlertError('Niet geauthoriseerd, gelieve opnieuw in te loggen...');
      }
      console.log('Something went wrong...', err);
    });
  }

}
