import { Component, OnInit } from '@angular/core';
import { Product } from '../objects/product.object';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.scss']
})
export class SubcategoryComponent implements OnInit {

  public description: string;
  public subCategory: string;
  public products: Product[];
  private sub: any;

  constructor(private route: ActivatedRoute, private dataService: DataService, private spinnerService: SpinnerService) {
    this.products = [];
    this.spinnerService.start();
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.subCategory = params['subcat'];
      this.dataService.getSubCategoryIntro(this.subCategory).then((result) => {
        this.description = result;
        this.products = [];
        this.dataService.getSubCategoryProductOverview(this.subCategory).then((result) => {
          let object = JSON.parse(JSON.parse(result));
          for (const product_obj of object) {
            this.products.push(new Product().serialize(product_obj));
          }
          this.spinnerService.stop();
        });
      }).catch((error) => {
        console.log('Something went wrong with overview: ', error);
        this.spinnerService.stop();
      });
    });
  }

}
