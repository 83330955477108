import { Component, OnInit } from '@angular/core';
import { CmsService } from '../services/cms.service';
import { Router } from '@angular/router';
import { SpinnerService } from '../services/spinner.service';
import { ActionEditor } from '../objects/action-editor.object';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-actions-editor',
  templateUrl: './actions-editor.component.html',
  styleUrls: ['./actions-editor.component.scss']
})
export class ActionsEditorComponent implements OnInit {

  public actions: ActionEditor[];

  constructor(private router: Router, private cmsService: CmsService,
    private spinnerService: SpinnerService, private alertService: AlertService) { 
      this.spinnerService.start();
      this.actions = [];
    }

  ngOnInit() {
    this.cmsService.getActionsEditorPageContent().then((obj: any) => {
      this.actions = [];
      console.log('Got actions...', obj);
      for (let i = 0; i < obj.length; i++) {
        this.actions.push(new ActionEditor().serialize(obj[i]));
      }
      this.spinnerService.stop();
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
      }
      console.log('Something went wrong...', err);
      this.spinnerService.stop();
    });
  }

  public removeAction(i: number): void {
    this.actions.splice(i, 1);
  }

  public addAction(): void {
    this.actions.push(new ActionEditor());
  }

  public submit(): void {
    console.log('GOING TO SUBMIT THIS:', this.actions);
    this.spinnerService.start();
    this.cmsService.updateActions(this.actions).then((obj: any) => {
      this.alertService.setAlertSuccess("Gegevens succesvol aangepast!");
      this.ngOnInit();
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
      }
      this.alertService.setAlertError("Iets is fout gegaan... Controleer de console en probeer opnieuw");
      console.log(err);
    });
  }

}
