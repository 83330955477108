import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  public active: boolean;

  public constructor(private spinner: SpinnerService, private cd: ChangeDetectorRef) {
      spinner.status.subscribe((status: boolean) => {
        this.active = status;
        this.cd.detectChanges();
      });
  }

  ngOnInit() {
  }

}
