import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { SpinnerService } from '../services/spinner.service';

declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public shortIntro: string;
  public openingHours: string;
  public contact: string;
  public banner: string;
  public img_paths: string[];

  constructor(private dataService: DataService, private spinnerService: SpinnerService) {
    this.spinnerService.start();
    this.img_paths = [];
  }

  ngOnInit() {
    this.dataService.getHomePageContent().then((obj) => {
      this.shortIntro = obj[0]['content'];
      this.openingHours = obj[1]['content'];
      this.contact = obj[2]['content'];
      this.banner = obj[3]['content'];
      this.img_paths = obj[4]['img_paths'];
      window.setTimeout(this.initCarousel, 500);
      this.spinnerService.stop();
    }).catch((err) => {
      alert("Something went wrong!");
      this.spinnerService.stop();
      this.img_paths = ['assets/img/gebouw.jpg', 'assets/img/gebouw.jpg'];
      this.initCarousel();
    });
  }

  private initCarousel(): void {
    let primary = $("#carousel");

    primary.owlCarousel({
      loop: true,
      items: 1,
      dots: false,
      nav: false,
      thumbs: false,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: false,
    });
  }

}
