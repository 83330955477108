import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { CmsService } from '../services/cms.service';
import { SpinnerService } from '../services/spinner.service';
import { SubCategory } from '../objects/subcategory.object';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-subcategory-editor',
  templateUrl: './subcategory-editor.component.html',
  styleUrls: ['./subcategory-editor.component.scss']
})
export class SubcategoryEditorComponent implements OnInit {

  public subCategories: SubCategory[];
  public category: string = "subcat";

  constructor(private router: Router, private dataService: DataService, private cmsService: CmsService,
        private spinnerService: SpinnerService, private alertService: AlertService) {
          this.subCategories = [];
        }

  ngOnInit() {
    this.getAllContent();
  }

  public submit(index: number): void {
    this.spinnerService.start();
    this.cmsService.updateSubCategory(this.subCategories[index]).then((res) => {
      this.subCategories = [];
      this.getAllContent();
      this.alertService.setAlertSuccess('Aanpassing succesvol!');
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
        this.alertService.setAlertError('Niet geauthoriseerd, gelieve opnieuw in te loggen...');
      }
      console.log('Something went wrong...', err);
      this.spinnerService.stop();
    });
  }

  private getAllContent(): void {
    this.spinnerService.start();
    this.cmsService.getAllSubCategories().then((result) => {
      console.log(result);
      for (let i = 0; i < result.length; i++) {
        this.subCategories.push(new SubCategory().serialize(result[i]));
      }
      console.log(this.subCategories);
      this.spinnerService.stop();
    }).catch((err) => {
      if (err === 'Unauthorized') {
        this.router.navigate(['home']);
        this.alertService.setAlertError('Niet geauthoriseerd, gelieve opnieuw in te loggen...');
      }
      console.log('Something went wrong...', err);
      this.spinnerService.stop();
    });
  }

}
