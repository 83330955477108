import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class SpinnerService {
  public status: Subject<boolean> = new Subject<boolean>();
  private _active: boolean = false;

  /**
   * Returns true if the spinner is active
   */
  public get active(): boolean {
    return this._active;
  }

  /**
   *
   */
  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  /**
   * Starts the spinner
   */
  public start(): void {
    this.active = true;
  }

  /**
   * Stops the spinner
   */
  public stop(): void {
    this.active = false;
  }
}